<template>
    <div>
        <b-card class="text-black">
            <b-card-body>
                <b-row>
                    <b-col md="3">
                        <b-form-group label="Role Name" label-for="addm-name">
                            <b-form-input id="addm-name"
                                          v-model="uName"
                                          placeholder="Enter Role Name" />
                        </b-form-group>
                    </b-col>
                    <b-col class="text-right mb-1" style="margin: auto">
                        <b-button variant="primary"
                                  pill
                                  style="margin-right: 2%"
                                  @click="searchRoles">
                            <feather-icon icon="SearchIcon" class="mr-50" />
                            Search
                        </b-button>
                        <b-button variant="primary"
                                  pill
                                  @click="createRole"
                                  v-if="hasPermission('role_create')">
                            <feather-icon icon="PlusIcon" class="mr-50" />
                            <span class="align-middle">Create</span>
                        </b-button>
                    </b-col>
                </b-row>
                <b-table responsive="sm"
                         :fields="fields"
                         :items="roles"
                         details-td-class="m-0 p-0"
                         small
                         v-if="hasPermission('role_read')"
                         class="text-black">
                    <template #cell(created_by)="row">
                        {{ row.item.created_by ? row.item.created_by.username : "" }}
                    </template>
                    <template #cell(updated_by)="row">
                        {{ row.item.updated_by ? row.item.updated_by.username : "" }}
                    </template>
                    <template #cell(manage)="row">
                        <b-button variant="info"
                                  pill
                                  size="sm"
                                  class="mr-1"
                                  @click="editRole(row.item)"
                                  v-if="hasPermission('role_update')">
                            Edit
                        </b-button>
                        <b-button variant="danger"
                                  pill
                                  size="sm"
                                  @click="removeRole(row.item)">
                            Delete
                        </b-button>
                    </template>
                </b-table>
                <b-row>
                    <b-col md="10">
                        <b-pagination :total-rows="totalDataLength"
                                      :per-page="per_page"
                                      align="center"
                                      v-model="currentPage" />
                    </b-col>
                    <b-col md="2">
                        <div style="float:right">
                            <h5 style="margin: 0; display: inline;" class="text-primary">Count: </h5>
                            <h5 style="margin: 0; display: inline;" align="right">
                                <strong>
                                    {{totalDataLength}}
                                </strong>
                            </h5>
                        </div>
                    </b-col>
                </b-row>
            </b-card-body>
        </b-card>
        <role-create-modal @modalClosed="onModalClosed"
                           :key="`create-${roleCreateModalCount}`" />
        <role-edit-modal :role="role"
                         :key="`edit-${roleEditModalCount}`"
                         @modalClosed="onModalClosed" />
        <b-overlay :show="show" spinner-variant="primary" no-wrap />
    </div>
</template>

<script>
    import util from "../../util";
    import RoleCreateModal from "./RoleCreateModal.vue";
    import RoleEditModal from "./RoleEditModal.vue";
    import { mapActions, mapGetters } from "vuex";
    import Ripple from "vue-ripple-directive";
    export default {
        components: {
            RoleCreateModal,
            RoleEditModal,
        },
        mixins: [util],
        directives: {
            Ripple,
        },
        data() {
            return {
                fields: [
                    { key: "name", label: "Role Name", sortable: true },
                    { key: "created_at", label: "Created At", sortable: true },
                    { key: "updated_at", label: "Updated At", sortable: true },
                    { key: "created_by", label: "Created By", sortable: true },
                    { key: "updated_by", label: "Updated By", sortable: true },
                    { key: "manage", label: "Manage" },
                ],
                roles: [],
                role: null,
                uName: null,
                roleCreateModalCount: 0,
                roleEditModalCount: 0,

                currentPage: 1,
                totalPageCount: null,
                totalDataLength: 0,
                per_page: 0,
                show: false,
            };
        },
        async mounted() {
            await this.retreiveRolesData();
        },
        methods: {
            ...mapActions({
                getPetitionRoles: "appData/getPetitionRoles",
                deleteRole: "appData/deleteRole",
            }),
            createRole() {
                this.roleCreateModalCount += 1;
                this.$nextTick(() => {
                    this.$bvModal.show("role-create-modal");
                });
            },
            async searchRoles() {
                try {
                    this.show = true;
                    const res = await this.getPetitionRoles({
                        page_number: 1,
                        name: this.uName,
                    });
                    this.roles = res.data.results;
                    this.totalPageCount = res.data.total_pages;
                    this.per_page = res.data.per_page;
                    this.totalDataLength = res.data.count;
                    this.show = false;
                } catch (error) {
                    this.show = false;
                    this.displayError(error);
                }
            },
            editRole(role) {
                this.role = role;
                this.roleEditModalCount += 1;
                this.$nextTick(() => {
                    this.$bvModal.show("role-edit-modal");
                });
            },
            async removeRole(role) {
                this.show = true;
                await this.$swal({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Yes, delete it!",
                    customClass: {
                        confirmButton: "btn btn-primary",
                        cancelButton: "btn btn-outline-danger ml-1",
                    },
                    buttonsStyling: false,
                }).then(async (result) => {
                    if (result.value) {
                        this.role = role;
                        try {
                            const res = await this.deleteRole({
                                pk: this.role.id,
                            });
                            if (res.status === 200) {
                                this.$swal({
                                    title: res.data.msg,
                                    icon: "success",
                                    timer: 1500,
                                });
                                this.show = false;
                                this.role = null;
                                await this.retreiveRolesData();
                            }
                        } catch (error) {
                            this.show = false;
                            this.displayError(error);
                        }
                    } else {
                        this.show = false;
                    }
                });
            },
            async onModalClosed() {
                try {
                    await this.retreiveRolesData();
                } catch (error) {
                    this.displayError(error);
                }
            },
            async retreiveRolesData() {
                try {
                    this.show = true;
                    const res = await this.getPetitionRoles({
                        page_number: this.currentPage,
                         name: this.uName,
                    });
                    this.roles = res.data.results;
                    this.per_page = res.data.per_page;
                    this.totalDataLength = res.data.count;
                    this.totalPageCount = res.data.total_pages;
                    this.show = false;
                } catch (error) {
                    this.show = false;
                    this.displayError(error);
                }
            },
        },
        computed: {
            ...mapGetters({
                getUser: "appData/getUser",
                hasPermission: "appData/hasPermission",
            }),
        },
        watch: {
            currentPage: {
                handler: async function (value) {
                    await this.retreiveRolesData();
                },
            },
        },
    };
</script>
<style>
    .text-black {
        color: black !important;
        /* font-size: 1.2rem; */
    }
</style>
<style scoped>
    ::placeholder {
        color: black;
    }
</style>
