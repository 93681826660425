<template>
  <b-modal
    id="role-edit-modal"
    title="Role Edit Modal"
    centered
    hide-footer
    size="lg"
    @hidden="resetModal"
    :no-close-on-esc="true"
    :no-close-on-backdrop="true"
  >
    <template #modal-title>
      <h2 class="m-0">Edit Role</h2>
    </template>

    <validation-observer ref="roleEditValidation">
      <b-form @submit.prevent>
        <b-row>
          <b-col cols="6">
            <b-form-group label="Role Name" label-for="name">
              <validation-provider
                #default="{ errors }"
                name="Role Name"
                rules="required"
              >
                <b-form-input
                  v-model="name"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Role Name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label="Role Code Name" label-for="codeName">
              <validation-provider
                #default="{ errors }"
                name="Role Code Name"
                rules="required"
              >
                <b-form-input
                  v-model="codeName"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Role Code Name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="6">
            <h6 class="text-primary font-weight-bold mb-2 text-center">
              Available Permissions
            </h6>
            <draggable
              :list="availablePermissionList"
              tag="ul"
              group="permissions"
              class="list-group list-group-flush cursor-move overflow-auto"
              style="height: 350px"
            >
              <b-list-group-item
                v-for="(listItem, index) in availablePermissionList"
                :key="index"
                tag="li"
              >
                <div class="d-flex">
                  <div class="ml-25">
                    <b-card-text class="mb-0 font-weight-bold">
                      {{ listItem.name }}
                    </b-card-text>
                    <b-card-text class="mb-0">
                      {{ listItem.module_name }}
                    </b-card-text>
                    <small>{{ listItem.description }}</small>
                  </div>
                </div>
              </b-list-group-item>
            </draggable>
          </b-col>
          <b-col cols="6">
            <h6 class="text-primary font-weight-bold mb-2 text-center">
              Allowed Permissions
            </h6>
            <draggable
              :list="allowedPermissionList"
              tag="ul"
              group="permissions"
              class="list-group list-group-flush cursor-move overflow-auto"
              style="height: 350px"
            >
              <b-list-group-item
                v-for="(listItem, index) in allowedPermissionList"
                :key="index"
                tag="li"
              >
                <div class="d-flex">
                  <div class="ml-25">
                    <b-card-text class="mb-0 font-weight-bold">
                      {{ listItem.name }}
                    </b-card-text>
                    <b-card-text class="mb-0">
                      {{ listItem.module_name }}
                    </b-card-text>
                    <small>{{ listItem.description }}</small>
                  </div>
                </div>
              </b-list-group-item>
            </draggable>
          </b-col>
        </b-row>
        <b-form-group class="text-right">
          <b-button
            type="submit"
            variant="primary"
            pill
            class="mr-1"
            @click="validationForm"
          >
            Update
          </b-button>
        </b-form-group>
      </b-form>
    </validation-observer>
    <b-overlay :show="show" spinner-variant="primary" no-wrap />
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import util from "../../util";
import { mapActions } from "vuex";
import draggable from "vuedraggable";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    draggable,
  },
  props: {
    role: Object,
  },
  mixins: [util],
  directives: {
    Ripple,
  },
  data() {
    return {
      name: "",
      codeName: "",
      required,
      availablePermissionList: [],
      allowedPermissionList: [],
      show: false,
    };
  },
  async mounted() {
    if (this.role) {
      this.name = this.role.name;
      this.codeName = this.role.code_name;
      this.allowedPermissionList = [...this.role.permissions];

      try {
        const res = await this.getPermissions();
        let allPermissions = res.data;

        const myArrayFiltered = allPermissions.filter((el) => {
          return this.allowedPermissionList.every((f) => {
            return f.id !== el.id;
          });
        });
        this.availablePermissionList = myArrayFiltered;
      } catch (error) {
        this.displayError(error);
      }
    }
  },
  methods: {
    ...mapActions("appData", [
      "updateRole",
      "getPermissions",
      
    ]),
    async validationForm() {
      const success = await this.$refs.roleEditValidation.validate();
      if (success) {
        await this.submit();
      }
    },
    async submit() {
      try {
        let newAllowedPermissions = [];
        this.allowedPermissionList.map((li) => {
          newAllowedPermissions.push({
            id: li.id,
          });
        });
        this.show = true;
        const res = await this.updateRole({
          name: this.name,
          code_name: this.codeName,
          pk: this.role.id,
          permissions: newAllowedPermissions,
        });
        if (res.status === 200) {
          this.$swal({
            title: res.data.msg,
            icon: "success",
            timer: 1500,
          });
          this.show = false;
          this.$nextTick(() => {
            this.$bvModal.hide("role-edit-modal");
          });
          this.$emit("modalClosed");
        }
      } catch (error) {
        this.show = false;
        this.displayError(error);
      }
    },
    resetModal() {
      this.name = "";
      this.codeName = "";
      this.availablePermissionList = [];
      this.allowedPermissionList = [];
    },
  },
  computed: {
    hospitalsNameandID() {
      let patientsData = [];
      this.hospitals.map((hosp) => {
        patientsData.push({ name: hosp.name, value: hosp.id });
      });
      return patientsData;
    },
  },
};
</script>

<style>
.tdWidth {
  width: 150px;
  max-width: 150px;
}

.trBgColor {
  background-color: #eee;
}
</style>
